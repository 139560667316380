<template>
  <v-row>
    <v-col cols="12" md="8" class="pa-3">
      <div class="d-flex justify-end mt-8">
        <v-btn color="primary" dark depressed @click="update" v-text="$t('common.publish')" />
      </div>
      <h2 class="mb-4 mt-8" v-text="$t('activity.editActivity.generalInformation')" />
      <v-row>
        <v-col cols="12">
          <v-text-field
            :value="activity.name" :label="$t('cluster.tickets.editMatchActivity.matchName')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :value="formatDate(activity.date.start)" :label="$t('cluster.tickets.editMatchActivity.matchDay')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :value="matchHour" :label="$t('cluster.tickets.editMatchActivity.matchHour')"
            outlined dense disabled
          />
        </v-col>
      </v-row>
      <h2 class="mb-4" v-text="$t('common.content')" />
      <p v-text="$t('cluster.tickets.editMatchActivity.contentDisclaimer')" />
      <v-row>
        <v-col cols="12">
          <geolocation
            :location="updatedLocation"
            :custom-label="`${$t('common.localization')} ${$t('common.optional')}`"
            @update="data => form.address = data"
            @blur="$v.form.address.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="form.description"
            :placeholder="`${$t('common.body')} ${$t('common.optional')}`" outlined dense
            height="100" @blur="$v.form.description.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <video-field
            v-model="form.video"
            @blur="$v.form.video.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <files-field
            v-model="form.attachments"
            class="mt-4"
            @input="$v.form.attachments.$touch()"
            @delete="$v.form.attachments.$touch()"
          />
        </v-col>
      </v-row>
    </v-col>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import dateFilter from '@/utils/mixins/dateFilter'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'EditEventGuildMatch',
  components: {
    Geolocation: () => import('@/components/formFields/Geolocation'),
    VideoField: () => import('@/components/formFields/VideoField'),
    FilesField: () => import('@/components/formFields/FilesField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
  },
  mixins: [formUtils, dateFilter, saveDialogUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    guildMatchActivityId: { type: String, required: true },
  },
  data: () => ({ form: {} }),
  validations: { form: { address: {}, description: {}, video: {}, attachments: {} } },
  computed: {
    ...mapGetters('project', ['activities']),
    updatedLocation: ({ form }) => form?.address.name,
    activity: ({ activities, guildMatchActivityId }) => activities.find(a => a.id === guildMatchActivityId),
    matchHour({ activity }) {
      return DateTime
        .fromISO(activity.date.start.toDate().toISOString())
        .setLocale(this.$i18n.locale)
        .toFormat('t')
    },
  },
  created() {
    this.form = {
      address: this.activity.address ?? null,
      description: this.activity.description ?? null,
      video: this.activity.video ?? null,
      attachments: this.activity.attachments ?? [],
    }
  },
  methods: {
    async update() {
      const { organizationId, projectId, guildMatchActivityId: activityId } = this
      await this.runAsync(() => this.$store.dispatch('guildMatches/updateActivity', { organizationId, projectId, activityId, data: this.form }))
      this.userAgreedToLeave = true
    },
  },
}
</script>
